
import React from 'react'
import { Button } from 'react-bootstrap'
import Typewriter from 'typewriter-effect'

import "../styles/Home.css"


const Home = () => {
  return (
    <div className='main'>
      
      
    
      <div className='content'>
        
          <h1>
                <Typewriter 
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    deleteSpeed: 120,
                    pauseFor: 80,
                    strings: ['Hi I am a <br />Front End Developer <br /> from Missoula Montana'],
                    
                    
                  }}
                />
          </h1>
          {/* <Button  className='btn btn-info'> Projects</Button> */}
        </div>
      </div>
     
    
   
   
  )
}

export default Home


