import "../styles/ReactProjects.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faImdb, faGithub } from "@fortawesome/free-brands-svg-icons";
import Card from 'react-bootstrap/Card';
import IMDBimg from "../../assets/movieApp.webp"
import ProfilePage from "../../assets/ProfileExample.webp"
import News from "../../assets/newsphoto.webp"
import { Container, Row, Col } from "react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export default function ReactProjects() {
  return (
    <div className="ReactProjects-wrapper">
    <div className="ReactProjects-container">
      <div className="ReactProjects-title">
        <h1 className="title">React Projects </h1>
        </div>
        <div className="card-style">
        <Container >
          <Row>      
                <Col style={{paddingBottom: '5rem',}}>
                <Card style={{ width: '35rem', border: 'solid 2px black', boxShadow: '10px -10px black'  }}>
                  <Card.Img variant="top" src={IMDBimg} />
                  <Card.Body>
                    <Card.Title>Imdb React Project</Card.Title>
                    <div className="card-text">
                    <Card.Text>
                       This project showcases my skills in building dynamic and responsive web applications using React.
                       The centerpiece of the application is a search bar that allows users to look up information on movies through the IMDb API. 
                       Once a movie is selected, the user can access its detailed description and leave a review, complete with a star rating. I also implemented a feature that enables users to delete their reviews.
                        While the project has room for further improvement, I am proud of what I was able to accomplish in just one week.
                    </Card.Text>
                    </div>
                    <div className="card-link">
                    <a href="https://jessemayer.github.io/pages/" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faImdb} className="imdb" /></a>
                    <a href="https://github.com/jessemayer/movie-app" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faGithub} className="imdb"/></a>
                    </div>
                  </Card.Body>
                </Card>
                </Col>

                <Col style={{paddingBottom: '5rem',}}>
                <Card style={{ width: '35rem', border: 'solid 2px black', boxShadow: '10px -10px black' }}>
                  <Card.Img variant="top" src={News} />
                  <Card.Body>
                    <Card.Title>News React Project</Card.Title>
                    <div className="card-text">
                    <Card.Text>For this project, I leveraged the power of React to build a dynamic web page that incorporates the News API and implements CRUD (Create, Read, Update, Delete) functionality. 
                      Specifically, the page showcases news articles from reputable sources such as TechCrunch, Wall Street Journal, and general news outlets, providing users with a diverse and informative browsing experience.
                      To ensure the project was accessible to a wide audience, I opted to host the page on AWS. However, it's worth noting that News API has certain limitations; for example, it can only be accessed on a local network or with a paid subscription.
                       Despite this challenge, I remained committed to delivering a high-quality product, and I encourage you to clone the repository to explore the code and see the project in action.
</Card.Text>
                    </div>
                    <div className="card-link">
                      <a href="https://github.com/jessemayer/News_Site" target='_blank' rel="noreferrer" ><FontAwesomeIcon icon={faGithub} className="imdb"/></a>
                     </div> 
                 </Card.Body>
                </Card>
                </Col>

                <Col style={{paddingBottom: '5rem',}}>
                <Card style={{ width: '35rem', border: 'solid 2px black', boxShadow: '10px -10px black' }}>
                  <Card.Img variant="top" src={ProfilePage} />
                  <Card.Body>
                    <Card.Title>Profile Project</Card.Title>
                    <div className="card-text">
                    <Card.Text>
                      This card showcases my portfolio website, which I built using React.js and Bootstrap.
                       Developing this site was both a fun and challenging experience, requiring careful attention to detail and persistence in debugging CSS.
                        The contact form is powered by email.js, ensuring that messages are reliably delivered to my inbox.
                         With this website, I aimed to create a polished and visually appealing representation of my work and skills,
                          while also providing easy access to my contact information for potential collaborators and employers.                
                    </Card.Text>
                    </div>
                    <div className="card-link">
                    <a href="https://www.jessemayerdevelopment.com/" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faHome} className="imdb" /></a>
                    <a href="https://github.com/jessemayer/jesse-portfolio" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faGithub} className="imdb"/></a>
                    </div>
                  </Card.Body>
                </Card>
                </Col>
          </Row>
        </Container>
          </div>
        
      </div>
    </div>
  );
}


