import TopBar from './components/Pages/TopBar';
import './components/styles/App.css'
import { Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Projects from './components/Pages/Projects';
import ReactProjects from './components/Pages/ReactProjects';
import Contact from './components/Pages/Contact';
import Resume from './components/Pages/Resume';




const App = () => {
 

  

  console.log(window.location);

  return (
    <>
    <div className='main-content'>
    <TopBar />
    
    
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Projects' element={<Projects />} />
        <Route path='/ReactProjects' element={<ReactProjects />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Resume' element={<Resume />} />
        <Route path='*' element={<Home />} /> 
      </Routes>
      </div>
      
    </>
    
  )
}

export default App;
