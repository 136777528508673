
import "../styles/Projects.css"
import React, { useState } from 'react';
import TicTacToe from "../../assets/tic.tac.toe.webp"
import Calculator from "../../assets/calculator.png"
import DomMan from "../../assets/dom-man.webp"
import { Container, Row, Col, Card, Carousel , Button} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faCalculator } from "@fortawesome/free-solid-svg-icons";


 

export default function  Projects() {

  
  return (
    <> 
        <div className="Projects-wrapper ">
          <div className="Projects-container">
            <div className="Projects-title">
              <h1 className="title">
                These are my HTML projects that I have been working on while in
                the Montana Code School Program
              </h1>
              </div>
              <br />
<div className="cards">
            <div className="card-styles">
            <Card className="text-center" >
      <Card.Header>Calculator</Card.Header>
      <Card.Body>
        <Card.Img className="mx-auto "
    src={Calculator}
    alt="calculator card"></Card.Img>
        <Card.Title>Calculator Project</Card.Title>
        <Card.Text className="card-text">
        This Basic Calculator is a web-based application that allows users to perform basic mathematical operations.
                       It is implemented using HTML, CSS, and JavaScript. The calculator manipulates the DOM (Document Object Model) by 
                       utilizing dataset and querySelector to perform calculations based on user input.
        </Card.Text>
        <div className="link">
        <a href="https://jessemayer.github.io/Calculator/" target='_blank' rel="noreferrer">Calculator Link<br/><FontAwesomeIcon icon= {faCalculator}className='imdb btn-info' /></a>
        <a href="https://github.com/jessemayer/Calculator" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faGithub} className='imdb btn-info'/></a>
        </div>
      </Card.Body>
      <Card.Footer className="text-muted">completed May 2023</Card.Footer>
    </Card>
          </div>
          

          <div className="card-styles">
            <Card className="text-center" >
      <Card.Header>Tic Tac Toe</Card.Header>
      <Card.Body>
        <Card.Img className="mx-auto card-image "
    src={TicTacToe}
    alt="Tic Tac Toe card"></Card.Img>
        <Card.Title>Tic Tac Toe Project</Card.Title>
        <Card.Text className="card-text">
        This project is a tic-tac-toe game, which I built using jQuery, HTML, CSS, and vanilla JavaScript.
                       The game is fully functional, and provides an interactive interface for users to play against each other.
                        The use of jQuery allowed me to efficiently manipulate the DOM and create dynamic content on the page.
                         Overall, this project helped me to improve my programming skills and gain a deeper understanding of web development.

        </Card.Text>
      </Card.Body>
      <div className="link">
            <a href="https://jessemayer.github.io/Tic-Tac-Toe/" target='_blank' rel="noreferrer" className='imdb'><button className="btn btn-info text-dark">Link to play</button></a>
            <a href="https://github.com/jessemayer/Tic-Tac-Toe" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faGithub} className='imdb'/></a>
        </div>
      <Card.Footer className="text-muted">completed December 2022</Card.Footer>
    </Card>
          </div>
          <div className="card-styles">
            <Card className="text-center" >
      <Card.Header>DOM Manipulation</Card.Header>
      <Card.Body>
        <Card.Img className="mx-auto card-image"
    src={DomMan}
    alt="recipe card"></Card.Img>
        <Card.Title>Recipe Project</Card.Title>
        <Card.Text className="card-text">
        For this project, I developed a recipe website that enables users to create, and delete their favorite recipes.
                       By implementing DOM manipulation techniques, I was able to create a user-friendly interface that allows users to easily add ingredients, as well as adjust preparation and meal time.
                        This project was an important step in expanding my web development skillset and furthering my understanding of effective user interface design.

        </Card.Text>
       
      </Card.Body>
      <div className="link">
              <a href="https://jessemayer.github.io/DOM-Manipulation-Recipe-Site/" target='_blank' rel="noreferrer"><button className="btn btn-info text-dark imdb">Link to Page</button></a>
              <a href="https://github.com/jessemayer/DOM-Manipulation-Recipe-Site" target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faGithub} className="btn btn-info text-dark imdb" /></a>
        </div>
      <Card.Footer className="text-muted">completed December 2022</Card.Footer>
    </Card>
          </div>         
          </div>
     </div>
  </div>

          
        
    
    </>
  );
      }