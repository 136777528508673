import React from 'react'
import "../styles/Contact.css"
import Office from '../../assets/contact.webp'
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'reactstrap';


const Contact = () => {
 
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d64364t', 'template_10mty2g',e.target, 'Qb89QrjG-EE8zQvTc')
      .then((result) => {
        alert('Message Successfully sent!')
        //       window.location.reload(false)
      }, (error) => {
        alert('Failed to send the message, please try again')
      });
       e.target.reset()
  };

  return (
    <>
    
    <div className='contact-form'>
      
    <div className='contact-header'>
      <div className='banner'>
      <h1>Lets Team Up!</h1>
      </div>
      </div>
      <div id='hero-img'>
      <img src={Office} className="rounded-full" alt="office-img"  />
      <br/>
    </div>
      <Form onSubmit={sendEmail}>

      
      <Form.Group className="mb-3 p-2 input mx-auto" as={Row}>
        <div className='p-4'>
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Name" name="name" />  
        
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" name="email"/>

        <Form.Label>Subject</Form.Label>
        <Form.Control type="text"  placeholder="Subject" name="subject" />
     
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} className="" name='message'/>
        <br/>
        <Button className='btn btn-info mb-3' value="Send Message">Send Message</Button>
        </div>
      </Form.Group>
      </Form>          
  </div>
  </>
  )
}

export default Contact
