import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import BrandLogo from '../../assets/logo.png'
import {
    faLinkedin,
    faGithub,
    faReact,
    faHtml5
  } from '@fortawesome/free-brands-svg-icons'
import {
    faEnvelope,
    faCode
  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function TopBar() {
  return (
    <>
    <div className='nav-bar'>
    <Navbar bg="dark" expand="sm" variant='dark' fixed="top" >
      <Container>
        <Navbar.Brand href="/">
        <img
        alt="logo"
        src={BrandLogo}
        style={{
            height:100,
            width:200,}}
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            <Nav.Link href="/about" className='navBar-about' style={{color:'white', fontSize: '2.5rem'}}>About Me</Nav.Link>
            
            <NavDropdown title={<FontAwesomeIcon icon={faCode} style={{  color:'green', height: 25,
                            padding: ".5rem" }}></FontAwesomeIcon>}   > 
              <NavDropdown.Item href="/reactProjects" style={{fontSize: "3.5rem"}}>
              <FontAwesomeIcon icon={faReact} />
              </NavDropdown.Item>
              <NavDropdown.Item href="/Projects" style={{fontSize: '3.5rem'}}>
                <FontAwesomeIcon icon={faHtml5} />
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href='/resume' className='navBar-resume' style={{color:'white', fontSize: '2.5rem'}}>Resume</Nav.Link>
          </Nav>
          <Nav.Link href="https://github.com/jessemayer" target='_blank'>
                <FontAwesomeIcon 
                    icon={faGithub} 
                    color="white" 
                    style={{height: 25, 
                            padding: ".5rem",}}
                    />
            </Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/jesse-mayer/" target='_blank'>
                <FontAwesomeIcon 
                    icon={faLinkedin} 
                    color="white" 
                    style={{height: 25,
                            padding: ".5rem",}}
                    />
            </Nav.Link>
            <Nav.Link href="/contact">
                <FontAwesomeIcon 
                    icon={faEnvelope} 
                    color="white" 
                    style={{height: 25,
                            padding: ".5rem",}}
                    />
            </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    </>
  );
}

export default TopBar;
