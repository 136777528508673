import PortImg from '../../assets/profilephoto.webp'
import HtmLogo from '../../assets/htmlogo.webp'
import Js from "../../assets/jslogo.webp"
import CSSLogo from "../../assets/csslogo.webp"
import reactLogo from "../../assets/react-logo.png"
import '../styles/About.css'





export default function About(props) {
  return (
    
    <>
   

    <div className='about'>
   
      <img src={PortImg} alt="Logo" className="port rounded-full h-50 w-50 mx-auto "/>
   
    <br />
    <div className="about-text mx-auto">
      <div className='grad-text'>
      <h2>Welcome to my portfolio website! <br />My name is Jesse Mayer</h2><br/>
      </div>
      <div className='about-content'>
      <p>I am a aspiring frontend web developer with a 20yr background in culinary arts.

As a chef, I developed a keen eye for detail, honed my creativity, and became an expert problem-solver. These skills have served me well in my transition to frontend web development, where attention to detail, creativity, and problem-solving are also essential for creating engaging user experiences.

Just like in the kitchen, I thrive on taking on complex challenges and working collaboratively to find innovative solutions. I know how to manage my time effectively, prioritize tasks, and deliver high-quality work on time.

But what sets me apart is my ability to think outside the box and bring a fresh perspective to every project. Whether I'm designing a website or developing an app, I always keep the end-user in mind, creating intuitive and user-friendly interfaces that are both aesthetically pleasing and functional.

So if you're looking for a frontend web developer who can bring a unique set of skills and qualities to your team, look no further. Let's work together to create something exceptional!</p>
</div>
</div>
    <div className="tech-stack">
      <div className='grad-text'>
        <h2>My Tech Stack</h2></div>
          <div className='tech-logo'>
          <img src={HtmLogo} alt='htmlogo' />
          <img src={Js} alt='jslogo' />
          <img src={CSSLogo} alt='csslogo' />
          <img src={reactLogo} alt='reactlogo' />
          </div>  
    </div>
    </div>
    </>
  )
}
