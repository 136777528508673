import React from 'react'
import "../styles/Resume.css"
import cv from "../../assets/JesseMayerCV.webp"


function Resume() {
  return (
    <div className='resume'>
      <img src={cv}
      className='cv-image' 
      alt="Jesse Mayer CV"/>
    </div> 
  )
}

export default Resume
